import React from "react"

import {
  Page,
  ContentPanel,
  ColumLayout,
  TitleContentPanel,
  Heading,
  Text,
  Flex,
  ToggleBlock,
} from "marketplace/app/components"

import FAQ_CONTENT from "marketplace/app/content/faq" 

const Docs = () => (
  <Page seo="Docs">
    <TitleContentPanel
      outerSx={{ bg: "secondary", color: "background" }}
      innerSx={{
        maxWidth: "550px",
      }}
    >
      <Heading variant="text.pageTitle">Documentation & FAQ</Heading>
      <Text variant="text.textBody" sx={{ width: "100%", maxWidth: "480px" }}>
        Here you can find useful information about how the marketplace works.
      </Text>
    </TitleContentPanel>
    <ContentPanel>
      <ColumLayout
        sx={{
          pl: ["32px", "32px", "70px", "90px"],
          pr: ["32px", "32px", "50px", "50px"],
          minHeight: ["500px", "550px", "600px", "790px"],
          height: "auto",
          justifyContent: "center",
        }}
      >
        <Flex
          column
          className="pink-scrollbar"
          sx={{
            height: "100%",
            maxHeight: "390px",
            overflowY: "auto",
          }}
        >
          <Flex column sx={{ maxHeight: "inherit", mr: "20px" }}>
            {FAQ_CONTENT.map((faq, qIndex) => (
              <ToggleBlock key={`faq-${qIndex}`} question={faq.question}>
                {faq.answer.map((answer, aIndex) => (
                  <Text
                    key={`faq-${qIndex}-${aIndex}`}
                    variant="text.textBody"
                    sx={{ mb: "20px" }}
                  >
                    {answer}
                  </Text>
                ))}
              </ToggleBlock>
            ))}
          </Flex>
        </Flex>
      </ColumLayout>
    </ContentPanel>
  </Page>
)

export default Docs
