export default [
  {
    question: "What is the GeoDB marketplace?",
    answer: [
      "The GeoDB Marketplace is a website dedicated to the sale of datasets extracted from the pool of geolocation data that we have, fed by applications such as GeoCash.",
      "Here you can preview the available data, request a budget for datasets of interest, buy the datasets with GEO tokens and, finally, download the data files as many times as you want.",
    ],
  },
  {
    question: "What do I need to buy a dataset?",
    answer: [
      "In order to operate in the Marketplace, that is, request a budget, buy datasets or retrieve previously purchased datasets, you need to use a MetaMask account in the browser.",
      "GEO tokens are only needed in the purchase process. To request a budget for a dataset of interest, as well as to recover the datasets already purchased, it will be enough to enable your MetaMask account while it is in the Marketplace.",
      "The Marketplace contract is deployed on the Binance network (BSC) and the datasets are purchased with GEO tokens. Therefore, you must have GEOs and you must connect to the BSC network in order to carry out the purchase process.",
      "Note that the purchase of the dataset is associated with the address of the portfolio account that you use. Your account in MetaMask is the way to authenticate in the marketplace and thus be able to request a budget, buy datasets and retrieve them later.",
    ],
  },
  {
    question: "What is the dataset I buy like?",
    answer: [
      "First, a dataset of interest is resolved by querying the data pool where the geographic area and time interval of interest are established.",
      "When the dataset corresponding to said query is purchased, associated with the account address in MetaMask, the private link is accessed through the order page to download it.",
      "The dataset can be downloaded as a file in CSV format. The dataset has the following fields or columns:",
      "address, country, admin_region_1, admin_region_2, place_name, place_latitude, place_longitude, altitude, latitude, longitude, battery, carrier, network, timestamp, antennadbm, cid, mcc, mnc, tac",
    ],
  },
  // {
  //   question: "( * ) How is the dataset priced?",
  //   answer: ["** TODO **"],
  // },
  // {
  //   question:
  //     "( * ) What happens to the remaining tokens after making a purchase?",
  //   answer: ["** TODO **"],
  // },
  {
    question: "How do I search for data of interest?",
    answer: [
      "To find a dataset of interest you have to go to the map on the search page.",
      "The dataset is solved from a query in which spatial and temporal parameters must be defined, to delimit the geographical area and the time interval of the data. By default the map is initialized with the data available globally during the last week.",
      "Using the date range selector you can choose the time period of interest, being the time unit of one day.",
      "By navigating through the map you can adjust the geographic area of interest. You can use the Geocoder to aid in navigating to a location. By default, the geographic area is defined with the limits of the window where the map is displayed (what you see is what you consult). You can also refine the area of interest by drawing geometry on the map (irregular, rectangular, or circular).",
    ],
  },
  {
    question: "What data is represented in the heatmap?",
    answer: [
      "Occurrence data is represented in the heatmap of the search page, which has been conveniently aggregated and weighted. In this way, as you navigate through the map and the data is updated, you can get an idea of where there is more or less amount of data, always relative to the data set that is being evaluated.",
      "S2 geometry is used for multilevel data aggregation, and 3 levels are used to adjust the representation of the data according to the display scale (global, regional and local).",
    ],
  },
  {
    question: "What is the dataset purchase process?",
    answer: [
      "To initialize the dataset purchase process, you must have sufficient GEO tokens in your MetaMask account. Currently, datasets are purchased one at a time, so you will need to complete the purchase process independently for each pre-order dataset available in the cart.",
      "The purchase process consists of 4 steps (3 of which you must confirm in MetaMask to continue, with 2 of these being transactions):",
      "1. Creation of personal signature in the browser with MetaMask. It requires confirmation by the user and does not entail any type of associated expense.",
      "2. Request to the backend to sign the purchase, associated with the account address and the query of interest. The user does not have to take any action, just wait.",
      "3. Once the personalized purchase authorization has been received, the user must confirm the transaction (Tx) directed to the Geo Token contract to approve the purchase operation to be carried out next from the Marketplace contract. This transaction only requires spending on GAS to carry it out.",
      "4. Finally, the user confirms the dataset purchase transaction (Tx), directed to the Marketplace contract. This transaction requires the expense corresponding to the dataset, along with the GAS to carry it out.",
    ],
  },
  {
    question: "How can I download my datasets?",
    answer: [
      "You can download the datasets that you have purchased from the order page. The datasets that you have purchased with your MetaMask account are listed here. Each dataset in the list can be expanded to show some metadata and the download link.",
      "To use the download link you must first request its activation by submitting a personal signature generated with MetaMask as requested. Once the link is enabled, you can use it to download the CSV file. You can repeat this process as many times as you like.",
    ],
  },
  // {
  //   question: "( * ) Can I share the datasets that I have purchased?",
  //   answer: ["** TODO **"],
  // },
  // {
  //   question: "( * ) How can I use my datasets?",
  //   answer: ["** TODO **"],
  // },
]